var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-12 padCustom"},[_c('div',{staticClass:"sectionFourBoxWrap"},[_c('div',{staticClass:"insideItemBoxWrap"},[(_vm.loading)?_c('div',{staticClass:"d-flex",staticStyle:{"flex":"1"}},[_c('v-skeleton-loader',{staticClass:"ma-2",attrs:{"type":"card","width":"100%","height":"150px"}}),_c('v-skeleton-loader',{staticClass:"ma-2",attrs:{"type":"card","width":"100%","height":"150px"}})],1):_c('draggable',{staticClass:"section-three row",class:_vm.isDisable ? 'section-three-in-active' : '',attrs:{"group":"table","disabled":_vm.isDisable},on:{"change":_vm.updateOrder},model:{value:(_vm.dashboardSort.section_4),callback:function ($$v) {_vm.$set(_vm.dashboardSort, "section_4", $$v)},expression:"dashboardSort.section_4"}},_vm._l((_vm.dashboardSort?.section_4),function(row,i){return _c('div',{key:i,staticClass:"col-lg-4",attrs:{"loading":_vm.loading}},[(row.card_name != 'Map')?_c('div',{staticClass:"largeItemBoxWrap"},[_c('div',{staticClass:"largeItemBoxHeading"},[_vm._v(" "+_vm._s(row.card_name)+" ")]),_c('div',{staticClass:"dashboardListingWrap"},[(
                  _vm.dashboadData[row.keys[0]] &&
                    _vm.dashboadData[row.keys[0]].length
                )?_c('ul',_vm._l((_vm.dashboadData[row.keys[0]]),function(list,index){return _c('li',{key:index},[_c('div',{staticClass:"prodImg"},[_c('img',{attrs:{"src":list.vehicle_model_image}})]),_c('div',{staticClass:"listingContentBox"},[_c('div',{staticClass:"insidelistingContentBox"},[_c('span',{staticClass:"labelField"},[_vm._v("Driver Name")]),_c('p',[_vm._v(_vm._s(list.customer_name))])]),(row.keys[0] == 'mostIdleVehicles')?_c('div',{staticClass:"insidelistingContentBox"},[_c('span',{staticClass:"labelField"},[_vm._v("Idle Time ")]),_c('p',[_vm._v(_vm._s(list.total_idle_time_in_seconds))])]):_c('div',{staticClass:"insidelistingContentBox"},[_c('span',{staticClass:"labelField"},[_vm._v("Speed"),_c('span',[_vm._v("(KM/Hr)")])]),_c('p',[_vm._v(_vm._s(list.total_max_speed))])]),_c('div',{staticClass:"insidelistingContentBox"},[_c('span',{staticClass:"labelField"},[_vm._v("Reg No")]),_c('p',{staticClass:"hightLightCol"},[_vm._v(" "+_vm._s(list.chassis_no)+" ")])])])])}),0):_c('ul',[_c('span',{staticClass:"noDataWrap"},[_vm._v("No Data Available")])])])]):_vm._e(),(
              row.card_name == 'Map' &&
                _vm.showMap &&
                (_vm.options.tenant_id || _vm.options.fleetId) &&
                !_vm.isVehicleLoad
            )?_c('div',{key:i,staticClass:"largeItemBoxWrap",attrs:{"loading":_vm.loading}},[_c('div',{staticClass:"largeItemBoxHeading"},[_vm._v(" "+_vm._s(row.card_name)+" ")]),(row.card_name == 'Map')?_c('div',{staticClass:"dashboardListingWrap"},[((_vm.options.tenant_id || _vm.options.fleetId) && _vm.showMap)?_c('DashboardMap',{attrs:{"channel-name":_vm.dashboadData.tenant_channel_name,"product":_vm.options.product}}):_vm._e()],1):_vm._e()]):_vm._e()])}),0)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }